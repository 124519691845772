<template>
  <div class="row">
    <div :class="'col-lg-12'">
      <div class="card">
        <div class="card-body">
          <form class="form-horizontal">
            <div class="form-body">
              <h3 class="box-title">Disposisi Siap Kirim</h3>
              <hr class="m-t-0" />
              <template>
                <!-- Select Penerima -->
                <div class="row">
                  <div class="col-md-12">
                    <label :class="['control-label',  'col-md-12']">
                      Diteruskan Kepada
                      <small style="color:red"></small>
                    </label>
                  </div>
                </div>
                <div class="row" v-if="userDisposisi.length > 0">
                  <div class="col-md-8">
                    <div class="form-group">
                      <label :class="['control-label', {'text-right': !isMobile}, 'col-md-1']"></label>
                      <div class="col-md-9 col-sm-9">
                        <div class="checkbox">
                          <input
                            @click="checkAll()"
                            v-model="isCheckAll"
                            id="index"
                            type="checkbox"
                          />
                          <label for="index">Centang Semua</label>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div :class="'col-md-6'" v-for="(value, index) in userDisposisi" :key="index">
                    <div class="row">
                      <label :class="['control-label', {'text-right': !isMobile}, 'col-md-1']"></label>
                      <div :class="'col-md-11 col-sm-12'">
                        <div>
                          <input
                            :id="'checkbox-a-'+value"
                            :value="value"
                            :checked="isChecked(value)"
                            @change="updateValue($event, value)"
                            type="checkbox"
                            class="checkbox m-r-5"
                          />
                          <input
                            class="checkbox m-r-5"
                            :id="'checkbox-'+value"
                            :value="value"
                            :checked="isCheckedValue(value)"
                            @change="updateCheckall($event, value)"
                            type="checkbox"
                          />
                          <label :for="'checkbox-'+value">{{value.text}}</label>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <hr class="m-t-10" />
                <div class="row">
                  <div class="col-md-12">
                    <label :class="['control-label','col-md-12']">Personal</label>
                    <div :class="['col-md-12']">
                      <Multiselect
                        v-model="payload.personal"
                        label="text"
                        track-by="value"
                        placeholder="Pilih Personal"
                        open-direction="bottom"
                        :options="filteredPersonalName"
                        :multiple="true"
                        :searchable="true"
                        :value="dropdownPersonal.value"
                        :loading="dropdownPersonal.isLoadingPersonal"
                        :internal-search="false"
                        :clear-on-select="true"
                        :close-on-select="true"
                        :options-limit="300"
                        :max-height="600"
                        :show-no-results="true"
                        :hide-selected="true"
                        @input="onChangePersonal"
                        @close="onTouchPersonal"
                        @search-change="personal"
                      >
                        <span slot="noResult">Oops! No Data found.</span>
                      </Multiselect>
                    </div>
                  </div>
                </div>
                <hr class="m-t-10" />
                <div class="row">
                  <div class="col-md-12">
                    <label :class="['control-label',  'col-md-12']">
                      Perintah Disposisi
                      <small style="color:red">*</small>
                    </label>
                  </div>
                </div>
                <div class="row">                  
                  <div :class="'col-md-6'" v-for="(row, index) in disposisiCommand" :key="index">
                    <div class="row">
                      <div class="col-md-12">
                        <label :class="['control-label',  'col-md-12']">
                          <strong>{{ row.text }}</strong>
                          <div v-for="(r, i) in row.data" :key="i">
                            <div class="checkbox">
                              <input
                                :value="r.value"
                                :checked="isCheckedDispo(r.value)"
                                :id="r.value"
                                type="checkbox"
                                @change="addCommand($event, r.value)"

                              />
                              <label :for="r.value">{{r.text}}</label>
                            </div>
                          </div>
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
              </template>
              <template>
                <div class="row m-t-10">
                  <div class="col-md-12">
                    <label :class="['control-label',  'col-md-12']">Catatan Disposisi</label>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-12 m-t-10 m-b-10">
                    <wysiwyg v-model="myHTML" />
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-12">
                    <div class="form-group">
                      <label :class="['control-label', 'col-md-12']">File Lampiran</label>
                      <h6 v-if="!files.length">Belum ada file diunggah</h6>
                      <table v-else class="table table-striped">
                        <tr>
                          <th>File</th>
                          <th>Aksi</th>
                        </tr>
                        <tr v-for="file in files" v-bind:key="file.filename">
                          <td>{{ file.file !== undefined ? file.filename:file.name }}</td>
                          <td>
                            <a
                              v-if="file.file !== undefined"
                              :href="file.file"
                              class="btn btn-success"
                              :download="file.filename"
                            >
                              <i class="fa fa-download"></i>
                            </a>
                            <i v-else>
                              <b>Belum di simpan</b>
                            </i>
                          </td>
                        </tr>
                      </table>

                      <Gallery :uploader="uploader" />
                    </div>
                  </div>
                </div>
              </template>
            </div>
            <hr />
            <div class="form-actions">
              <div class="row">
                <div class="col-md-12">
                  <div class="row">
                    <div class="col-md-offset-3 col-md-9">
                      <button
                        type="button"
                        @click="submit()"
                        class="btn btn-success"
                      >
                        <i class="mdi mdi-send"></i>
                        Kirim
                      </button>&nbsp;
                      <button type="button" @click="goBack()" class="btn btn-inverse">Cancel</button>
                      <!-- <button type="button" @click="showModal({type:'cancel'})" class="btn btn-inverse">Cancel</button> -->
                    </div>
                  </div>
                </div>
                <div class="col-md-6"></div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Input from "@/components/input/input";
import Multiselect from "vue-multiselect";
import Upload from "@/components/input/upload.vue";
import axios from "axios";
import store from "@/store";
import moment from "moment";
export default {
  components: {
    Upload,
    Input,
    Multiselect
  },
  computed: {
    editMode() {
      return !!this.$route.params.id;
    },
    isMobile() {
      return this.$store.state.isMobile;
    },
    filteredPersonalName() {
      let filter = this.payload.personal;
      let data = this.dropdownPersonal.personalList.filter(elem => {
        return elem.parent == filter.value;
      });
      return data;
    }
  },
  beforeCreate() {
    axios
      .get(`/disposition_already/${this.$route.params.id}`)
      .then(response => {
        const state = {
          loaded: true
        };
        this.$store.commit("dispositionAlreadySend/STATE", state);
        this.edit(response.data.data);

        this.myHTML = response.data.data.note;

        const dataReceiver = response.data.data.receiver;
        dataReceiver.forEach(element => {
          this.dataReceiver.push(element.value);
        });

        const dataIntruksi = response.data.data.intruksi;
        dataIntruksi.forEach(element => {
          this.dataIntruksi.push(element.value);
        });
      });
  },
  created() {
    var query = "";
    this.getUserDispo(query);
    this.personal(query);
    this.getIntruksi(query);
  },
  data() {
    const uploader = this.fineUploader(this, "disposition_in");
    return {
      uploader,
      dropdownPersonal: {
        isTouched: false,
        personalList: [],
        value: [],
        isLoadingPersonal: false
      },
      payload: {
        personal: []
      },
      addedUser: [],
      addedUserFirst: [],
      userDisposisi: [],
      isCheckAll: false,
      dataReceiver: [],
      dataIntruksi: [],
      disposisiCommand: [],
      addedCommand: [],
      checkedData: true,
      myHTML: "",
      files: []
    };
  },
  methods: {
    intialize() {
      try {
        if (this.editMode) {
          axios
            .get(`/disposition_already/${this.$route.params.id}`)
            .then(response => {
              const state = {
                loaded: true
              };
              this.$store.commit("dispositionAlreadySend/STATE", state);
              this.edit(response.data.data);
              this.myHTML = response.data.data.note;
              const dataReceiver = response.data.data.receiver;
              dataReceiver.forEach(element => {
                this.dataReceiver.push(element.value);
              });

              const dataIntruksi = response.data.data.intruksi;
              dataIntruksi.forEach(element => {
                this.dataIntruksi.push(element.value);
              });
            });
        } else {
          this.$store.dispatch("dispositionAlreadySend/onAdd");
        }
      } catch (e) {
        this.handleApiError(e);
        this.error = e.message;
      }
    },
    edit(data) {
      this.payload = {
        receiver: data.receiver,
        personal: data.personal,
        note: data.note,
        intruksi: data.intruksi,
        files: data.files
      };
      this.addedUser = data.receiver.map(element=>{
        return {
          defaultValue: parseInt(element.status),
          text:element.text,
          value:element.value,
          checked:(parseInt(element.status)==1)?false:true
        }
      });
      this.addedCommand = data.intruksi.map(element=>{
        return element.value;
      });
      this.files = data.files;
      this.dropdownPersonal.value = data.personal;
    },
    isChecked(index){
      let isChecked = false;
      let data = this.addedUser.filter(element => {
        let checked = false;
        // console.log(element);
        if(element.text == index.text){
          if(element.checked == true){
            checked = true;
          }
        } 
        else if(element.value == index.value){
          if(element.checked == true){
            checked = true;
          }
        }
        return checked == true;
      });
      // console.log(index.text);
      if(data.length){
        isChecked = true;
      }
      return isChecked;
    },
    isCheckedDispo(value){
      let isChecked = false;
      let data = this.addedCommand.filter(element => {
        let checked = false;
        if(element == value){
          checked = true;
        }
        return checked == true;
      });
      // console.log(data.length);
      if(data.length){
        isChecked = true;
      }
      return isChecked;
    },
    isCheckedValue(index){
      let isChecked = false;
      let data = this.addedUser.filter(element => {
        // console.log(element);
        let checked = false;
        if(element.text == index.text){
            checked = true;
        }
         else if(element.value == index.value){
            checked = true;
        }
        return checked == true;
      });
      if(data.length){
        isChecked = true;
      }
      // console.log(index);
      return isChecked;
    },
    isInArray(value, array) {
      return array.indexOf(value) > -1;
    },
    getUserDispo(query) {
      var user = JSON.parse(localStorage.getItem("user"));
      axios
        .post(
          `disposition_in/disposition?s=${query}&structural=${user.structural_position_id}&unit_id=${user.unit_id}`
        )
        .then(res => {
          res.data.items.forEach(row => {
            var datas = Object.values(this.dataReceiver);
            var n = datas.includes(row.value);
            this.userDisposisi.push({
              value: row.value,
              text: row.text,
              defaultValue: 1,
              checked: n
            });
          });
        });
    },
    addCommand(e, value){
      if (e.target.checked) {
        this.addedCommand.push(value);
      }else{
        this.addedCommand = this.addedCommand.filter(element=>{
          return element != value;
        })
      }
    },
    getIntruksi() {
      axios.get(`document_statuses/list`).then(res => {
        res.data.items.forEach(row => {
          const data = [];
          var datas = Object.values(this.dataIntruksi);
          row.data.forEach(r => {
            var n = datas.includes(r.value);
            data.push({
              value: r.value,
              text: r.text,
              checked: n
            });
          });
          this.disposisiCommand.push({
            value: row.value,
            text: row.text,
            data: data
          });          
        });
      });
    },
    personal(query) {
      this.dropdownPersonal.isLoadingPersonal = true;
      axios
        // .get(`disposition_already/personal_list?s=${query}`)
        .get(`document_in/list?s=${query}`)
        .then(res => {
          this.dropdownPersonal.personalList = res.data.items;
          this.dropdownPersonal.isLoadingPersonal = false;
        })
        .catch(err => {
          if (err.response.status === 401) {
            this.$store.dispatch("auth/logout");
          }
        });
    },
    isInvalidPersonal() {
      return (
        this.dropdownPersonal.isTouched &&
        this.dropdownPersonal.value.length === 0
      );
    },
    onTouchPersonal() {
      this.dropdownPersonal.isTouched = true;
    },
    onChangePersonal(value) {
      this.dropdownPersonal.value = value;
    },
    checkAll() {
      this.isCheckAll = !this.isCheckAll;
      this.addedUser = [];
      this.addedUserFirst = [];
      if (this.isCheckAll) {
        for (var key in this.userDisposisi) {
          this.userDisposisi[key].defaultValue = 1;
          this.addedUser.push(this.userDisposisi[key]);
        }
      }
    },
    findValue(data) {
      return data.value === 1;
    },
    changeDefaultValueAsOne(data) {
      return (data.defaultValue = 1);
    },
    changeDefaultValueAsTwo(data) {
      return (data.defaultValue = 2);
    },
    updateValue(e, index) {
      if (e.target.checked) {
        let data = this.addedUser.filter(element => {
          let checked = false;
          if(element.text == index.text){
              checked = true;
          }
          return checked == true;
        });
        if(data.length){
          this.addedUser = this.addedUser.map(element=>{
            if(element.text == index.text){
              return {
                defaultValue:2,
                text:element.text,
                checked:true,
                value:element.value
              }
            }else{
              return element;
            }
          });
        }else{
          this.addedUser.push({
            defaultValue:2,
            text:index.text,
            checked:true,
            value:index.value
          })
        }
      } else {
        let data = this.addedUser.filter(element => {
          let checked = false;
          if(element.text == index.text){
              checked = true;
          }
          return checked == true;
        });
        if(data.length){
          this.addedUser = this.addedUser.map(element=>{
            if(element.text == index.text){
              return {
                defaultValue:1,
                text:element.text,
                checked:false,
                value:element.value
              }
            }else{
              return element;
            }
          });
        }else{
          this.addedUser.push({
            defaultValue:1,
            text:index.text,
            checked:false,
            value:index.value
          })
        }
      }
    },
    updateCheckall(e, index) {
      if (e.target.checked) {
        this.addedUser.push({
          defaultValue:1,
          text:index.text,
          checked:false,
          value:index.value
        })
      } else {
        this.addedUser = this.addedUser.filter(element => {
          let checked = false;
          if(element.text != index.text){
              checked = true;
          }
          return checked == true;
        });
      }
    },
    goBack() {
      this.$store.dispatch("dispositionAlreadySend/onCancel");
    },
    submit() {
      if (this.payload.photos) {
        this.myFile = this.payload.photos.split(",");
      }
      const addedUser = [];
      this.addedUser.forEach(value => {
        if (value != null || value != false || value != undefined) {
          addedUser.push(value);
        }
      });
      const payload = {
        agenda_number: this.$store.state.documentIn.detail.agenda_number,
        user: addedUser,
        disposisi: this.addedCommand,
        personal: this.payload.personal,
        catatan: this.myHTML,
        files: this.files,
        is_send: true
      };

      const data = JSON.stringify(payload);
      this.$validator.validateAll().then(success => {
        if (success) {
          this.$store.dispatch("dispositionAlreadySend/submitDisposisi", {
            data,
            id: this.$route.params.id
          });
        }
      });
    }
  }
};
</script>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style>
.multiselect__tags {
  border: 1px solid #ced4da !important;
}
.invalid .multiselect__tags {
  border-color: #f04124 !important;
  padding: 5px 40px 0 8px !important;
}
.invalid .typo__label {
  color: #f04124;
  font-size: 0.8125rem;
}
.multiselect {
  color: #54667a !important;
}
.multiselect__select {
  height: 41px !important;
}
</style>
